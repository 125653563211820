<template>
  <div
    v-if="
      topicData != null && recCommendData != null && recCommendData.length > 0
    "
  >
    <!-- <el-divider style="margin-top: 50px">相关推荐</el-divider> -->
    <!-- <div class="myStudy-ul">
      <ul>
        <li>
          <span
            v-for="(recItem, recIndex) in recCommendData"
            :key="recIndex"
            class="aHred"
            @click="gotoSction(recItem.sectionId)"
            >{{ recItem.partTitle }} - {{ recItem.chapterTitle }} -
            {{ recItem.sectionTitle }}</span
          >
        </li>
      </ul>
    </div> -->
    <dl>
      <div style="padding-bottom: 10px; font-size: 20px; font-weight: 600">
        相关推荐
      </div>
      <div v-for="(recItem, recIndex) in recCommendData" :key="recIndex">
        <dd :class="setClass(recIndex)" @click="gotoSction(recItem)">
          <div class="itemimg">
            <!-- <img src="~@/assets/course/img-item-1.png"> -->
            <img
              v-if="recItem.refKind == 'CHAPTER' && recItem.chapterCover"
              :src="recItem.chapterCover"
            />
            <img
              v-else-if="recItem.refKind == 'SEGMENT' && recItem.segmentCover"
              :src="recItem.segmentCover"
            />
            <img v-else src="~@/assets/course/card_icon.jpg" />
          </div>
          <div class="contertp30">
            <div
              style="margin-top: 21px"
              class="infotitle"
              :title="recItem.partTitle + '' + recItem.chapterTitle"
            >
              {{ recItem.partTitle }} {{ recItem.chapterTitle }}
            </div>
            <div
              v-if="recItem.refKind == 'SEGMENT'"
              style="margin-top: 5px"
              class="infotitle"
              :title="recItem.sectionTitle + '' + recItem.segmentTitle"
            >
              {{ recItem.sectionTitle }} {{ recItem.segmentTitle }}
            </div>
            <!-- <div class="infoFont">{{ courseItem.subtitle }}</div>
          <div class="infoFont">
            <span v-html="courseItem.introduction"></span>
          </div>
          <div v-if="courseItem.published" class="infoButton on">点击进入</div>
          <div v-else class="infoButton">即将上线</div> -->
          </div>
        </dd>
      </div>
    </dl>
  </div>
</template>
<script>
import { getRecommendSections } from "@/api/examController";
export default {
  name: "TopicRec",
  components: {},
  props: {
    topicData: {
      type: Object,
      default: null,
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    topicData: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.play();
        this.getRecommendSections();
      },
      deep: true,
    },
    valid: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.isValid = this.valid;
      },
    },
  },
  data() {
    return {
      recCommendData: null,
    };
  },
  created() {
    this.getRecommendSections();
  },
  methods: {
    gotoSction(item) {
      var query = {};
      if (item.refKind == "CHAPTER") {
        query.chapterId = item.refId;
      } else if (item.refKind == "SEGMENT") {
        query.segmentId = item.refId;
      }
      query.time = new Date().getTime();
      this.$router.push({
        path: "/Subject",
        query: query,
      });
    },
    getRecommendSections() {
      //const id = sessionStorage.getItem("COURSE_ID");
      getRecommendSections(this.topicData.id, {
        limit: 5,
      }).then((res) => {
        if (res.code == 20000) {
          this.recCommendData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    setClass(index) {
      if (index % 3 != 0) {
        return "marginLeft";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.myStudy-ul {
  padding: 15px 0;
  font-size: 16px;
  li {
    clear: both;
    position: relative;
    color: #575b67;
    line-height: 40px;
    padding: 5px 0;
    &.lh64 {
      line-height: 64px;
      span {
        float: left;
        display: block;
      }
      div {
        display: block;
        height: 64px;
        background: #ffcf00;
        border-radius: 6px;
        border: 1px solid #707070;
        padding: 0 15px;
        float: left;
        font-size: 60px;
        margin-left: 15px;
        margin-right: 10px;
        line-height: 64px;
        font-weight: bold;
        color: #090909;
      }
    }
    i {
      color: #173d7c;
      font-style: normal;
      font-weight: bold;
    }
    .aHred {
      font-size: 16px;
      font-weight: bold;
      color: #173d7c;
      text-decoration: underline;
      margin-left: 15px;
      cursor: pointer;
    }
    // &::before{
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   display: block;
    // }
    &::after {
      clear: both;
      display: block;
      content: "";
    }
  }
}
.marginLeft {
  margin-left: 25px;
}
dl {
  padding: 10px;
  list-style: none;
  //margin: 0 auto;
  margin: 8px;
  opacity: 1;
  border: 1px solid #707070;
  border-radius: 8px 8px 8px 8px;
  &::after {
    display: block;
    clear: both;
    content: "";
  }
  dd {
    padding: 0;
    list-style: none;
    margin: 0 auto;
    width: calc(33.333333% - 18px);
    //min-height: 128px;
    //height: 186px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    //border: 1px solid #aeaeae;
    float: left;
    cursor: pointer;
    //margin-top: 20px;
    //margin-right: 27px;
    //margin-left: 27px;
    margin-bottom: 20px;
    // &:nth-child(4n) {
    //   margin-right: 0;
    // }
    .itemimg {
      width: 100%;
      //height: 136px;
      img {
        width: 100%;
        //height: 100%;
      }
    }
    .contertp30 {
      //padding: 5px 30px 20px 30px;
      .infotitle {
        // text-align: center;
        // font-size: 12px;
        // font-weight: 400;
        // //color: #575b67;
        // line-height: 30px;
        // color: #173d7c;
        // //border-bottom: 1px solid #707070;
        // //text-decoration: underline;
        // height: 30px;
        // overflow: hidden;
        padding: 0px, 5px, 0px, 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #575b67;
        //text-align: center;
      }
      .infoFont {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        padding-top: 8px;
        height: 34px;
        overflow: hidden;
      }
      .infoButton {
        width: 138px;
        height: 38px;
        background: #777777;
        border-radius: 16px;
        border: 1px solid #5e5e5e;
        line-height: 38px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        margin: 15px auto 0 auto;
        cursor: pointer;
        &.on {
          background: #173d7c;
          border: 1px solid #173d7c;
        }
      }
    }
  }
}
</style>
