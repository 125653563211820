<template>
  <div>
    <div class="TitleReport1CenterSelect" @click="showOrHide">
      <div v-if="answerData.ret == 'CORRECT'" class="slect">
        <span>问题{{ answerIndex }}</span>
        <i><img src="~@/assets/TrueTopic/select-1.png" /></i>
      </div>
      <div v-else class="slect on">
        <span>问题{{ answerIndex }}</span>
        <i><img src="~@/assets/TrueTopic/select-2.png" /></i>
      </div>
    </div>
    <div v-if="isShow" class="TrueTopicItem">
      <div class="TrueTopicItemTitle">
        <div class="TrueTopicItemTitleFl">
          <i><img src="~@/assets/TrueTopic/iconshang.png" /></i
          ><span v-if="answerData.topicVo.type == 'MULTIPLE_CHOICE'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(多选题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'TRUE_OR_FALSE'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(判断题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'SINGLE_CHOICE'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(单选题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'ESSAY_QUESTION'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(问答题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'GAP_FILLING'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(填空题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'MATCHING_ITEM'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(匹配题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'ACCOUNTING'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(会计分录题)</span></span
          >
          <span v-if="topicData.topicVo.type == 'COMPREHENSIVE'"
            >第{{ answerIndex }}题
            <span v-if="isShowQuestionTitle">(综合题)</span></span
          >
          <div v-if="isFavorite == false" @click="handleFavorite('add')">
            <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i
            ><span>加入收藏夹</span>
          </div>
          <div v-else @click="handleFavorite('remove')">
            <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i
            ><span>取消收藏夹</span>
          </div>
        </div>
        <div v-if="refKind == 'Exam'" class="TrueTopicItemTitleFr">
          用时间：{{ secondsToTimeStr(topicData.spend) }}
        </div>
      </div>
      <!--单选题-->
      <div v-if="topicData.topicVo.type == 'SINGLE_CHOICE'">
        <single-choice
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <div v-if="topicData.topicVo.type == 'TRUE_OR_FALSE'">
        <true-or-false
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <div v-if="topicData.topicVo.type == 'MULTIPLE_CHOICE'">
        <multiple-choice
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <!--问答题-->
      <div v-if="topicData.topicVo.type == 'ESSAY_QUESTION'">
        <essay-question
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <!--填空题-->
      <div v-if="topicData.topicVo.type == 'GAP_FILLING'">
        <gap-filling
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <!--匹配题-->
      <div v-if="topicData.topicVo.type == 'MATCHING_ITEM'">
        <matching-item
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <!--会计分录题-->
      <div v-if="topicData.topicVo.type == 'ACCOUNTING'">
        <accounting
          :ret="topicData.ret"
          :questionData="topicData.topicVo.questions[0]"
          :answerData="topicData.answers[0]"
        />
      </div>
      <div v-if="topicData.topicVo.type == 'COMPREHENSIVE'">
        <comprehensive :topicData="topicData" />
      </div>
      <topic-explain :topicData="topicData.topicVo" :valid="valid" />
      <topic-rec :topicData="topicData.topicVo" />
      <!-- <div class="TrueTopicItemFont">
        <div class="TrueTopicItemFontTitle">
          贷款（电子借条信用贷款 [5] ）简单通俗的理解，就是需要 利息 的借钱。.
          贷款是 银行 或其他 金融机构 按一定利率和必须归还等条件出借 货币资金
        </div>
        <div class="TrueTopicItemFontInfo">
          <video src="" controls></video>
          <div class="TrueTopicItemFontpl">
            <div class="TrueTopicItemFontpl-title">错题解析视频</div>
            <div class="TrueTopicItemFontpl-but">
              <button @click="purchaseFn()">购买</button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { secondsToTimeStr } from "@/utils/validate";
//import AnswerResultTip from "./AnswerResultTip.vue";
import { addFavorite, removeFavorite } from "@/utils/favorite";
import SingleChoice from "@/components/TopicResult/SingleChoice.vue";
import TrueOrFalse from "@/components/TopicResult/TrueOrFalse.vue";
import MultipleChoice from "@/components/TopicResult/MultipleChoice.vue";
import EssayQuestion from "@/components/TopicResult/EssayQuestion.vue";
import GapFilling from "@/components/TopicResult/GapFilling.vue";
import MatchingItem from "@/components/TopicResult/MatchingItem.vue";
import Accounting from "@/components/TopicResult/Accounting.vue";
import Comprehensive from "@/components/TopicResult/Comprehensive.vue";
import TopicExplain from "@/components/TopicResult/TopicExplain.vue";
import TopicRec from "@/components/TopicResult/TopicRec.vue";
export default {
  name: "Topic",
  components: {
    SingleChoice,
    TrueOrFalse,
    MultipleChoice,
    EssayQuestion,
    GapFilling,
    MatchingItem,
    Accounting,
    Comprehensive,
    TopicExplain,
    TopicRec,
  },
  watch: {
    answerData: {
      handler(val, oldval) {
        this.topicData = this.answerData;
        this.isFavorite = this.isFavoriteTopics(this.topicData.topicVo.id);
        this.isShow = false;
      },
    },
  },
  props: {
    answerData: {
      type: Object,
      default: null,
    },
    answerIndex: {
      type: Number,
      default: 0,
    },
    refKind: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    isShowQuestionTitle: {
      type: Boolean,
      default: true,
    },
    wrongTopicRefKind: {
      type: String,
      default: null,
    },
    wrongTopicRefId: {
      type: Number,
      default: null,
    },
    segmentId: {
      type: Number,
      default: null,
    },
    chapterId: {
      type: Number,
      default: null,
    },
    sectionId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isShow: false,
      isFavorite: false,
      topicData: null,
    };
  },
  created() {
    this.isShow = false;
    this.topicData = this.answerData;
    this.isFavorite = this.isFavoriteTopics(this.topicData.topicVo.id);
  },
  methods: {
    showOrHide() {
      this.isShow = !this.isShow;
    },
    secondsToTimeStr(second) {
      return secondsToTimeStr(second);
    },
    async handleFavorite(type) {
      if (type === "add") {
        //await addFavorite(this.topicData.topicVo.id);
        await addFavorite(this.topicData.topicVo.id, {
          segmentId: this.segmentId,
          refKind: this.wrongTopicRefKind,
          refId: this.wrongTopicRefId,
          chapterId: this.chapterId,
          sectionId: this.sectionId,
        });
        this.isFavorite = this.isFavoriteTopics(this.topicData.topicVo.id);
      } else if (type === "remove") {
        await removeFavorite(this.topicData.topicVo.id);
        this.isFavorite = this.isFavoriteTopics(this.topicData.topicVo.id);
        //this.$emit("set-favorite-callback");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.TitleReport1CenterSelect {
  padding: 10px 0;
  margin-top: 20px;
  .slect {
    position: relative;
    float: left;
    height: 20px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #707070;
    padding: 0 12px;
    span {
      float: left;
      font-size: 12px;
    }
    i {
      float: left;
      margin-left: 5px;
      font-size: 0;
      margin-top: 3px;
    }
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    &.on {
      height: 20px;
      background: #8d0005;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #6d0004;
      color: #ffffff;
    }
  }
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .TitleReport1Center {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      ul {
        li {
          padding-bottom: 20px;
        }
      }
      .FontTitle {
        font-weight: bold;
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      .TitleReport1CenterTitle {
        font-size: 36px;
        font-weight: 400;
        color: #060606;
        line-height: 60px;
        margin-top: 10px;
      }
      .TitleReport1Center-Title {
        font-weight: bold;
        font-size: 28px;
        font-weight: bold;
        color: #575b67;
      }
      .TitleReport1Center-main {
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #707070;
        margin-top: 20px;
        .TitleReport1Center-mainItem {
          border-bottom: 1px solid #707070;
          div {
            float: left;
            width: 25%;
            display: block;
            box-sizing: border-box;
            text-align: center;
            border-left: 1px solid #707070;
            line-height: 215px;
            font-size: 0;
            height: 215px;
            overflow: hidden;
            img {
              display: inline-block;
            }
            span {
              font-size: 60px;
              font-weight: bold;
              color: #7b7b7b;
              display: inline-block;
              margin-left: 15px;
              line-height: 215px;
            }
            &:first-child {
              margin-left: 0;
              border-left: 0;
            }
          }
          &::after {
            clear: both;
            display: block;
            content: "";
          }
        }
        .TitleReport1Centerinco {
          padding: 25px 30px;
        }
        .TitleReport1CenterFont {
          font-size: 16px;
          font-weight: 400;
          color: #636363;
          line-height: 36px;
          span {
            color: #8d0005;
          }
        }
        .TitleReport1CenterSelect {
          padding: 10px 0;
          margin-top: 20px;
          .slect {
            position: relative;
            float: left;
            height: 20px;
            background: #ffffff;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #707070;
            padding: 0 12px;
            span {
              float: left;
              font-size: 12px;
            }
            i {
              float: left;
              margin-left: 5px;
              font-size: 0;
              margin-top: 3px;
            }
            &::after {
              clear: both;
              display: block;
              content: "";
            }
            &.on {
              height: 20px;
              background: #8d0005;
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
              border: 1px solid #6d0004;
              color: #ffffff;
            }
          }
          &::after {
            clear: both;
            display: block;
            content: "";
          }
        }
        &::after {
          clear: both;
          display: block;
          content: "";
        }
      }
      .TitleReport1Center-tItem {
        margin-top: 10px;
        height: 40px;
        .TitleReport1Center-topic {
          display: block;
          height: 40px;
          background: #636363;
          border: 1px solid #707070;
          border-radius: 20px;
          line-height: 40px;
          padding: 0 25px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          float: left;
        }
        .TitleReport1Center-time {
          float: right;
          line-height: 40px;
          font-size: 16px;
          span {
            color: #8d0005;
          }
        }
      }
      .page-center {
        height: 32px;
        background: url("~@/assets/TrueTopic/itembg.png") repeat-x left;
        text-align: center;
        margin-top: 30px;
        .itemPage {
          height: 30px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 15px;
          display: inline-block;
          box-sizing: content-box;
          .left {
            float: left;
            background: url("~@/assets/TrueTopic/left.png") no-repeat center;

            cursor: pointer;
            padding: 0 15px;
            border-right: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .right {
            float: right;
            background: url("~@/assets/TrueTopic/right.png") no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-left: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .center {
            padding: 0 70px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #575b66;
          }
          &:hover {
            border: 1px solid #8d0005;
            .left {
              border-right: 1px solid #8d0005;
            }
            .right {
              border-left: 1px solid #8d0005;
            }
            .center {
              color: #8d0005;
            }
          }
        }
      }
      .TitleReport1Center-Font {
        margin-top: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .TitleReport1Center-or {
        margin-top: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 6px;
        dl {
          dd {
            .TitleReport1Center-orDdTitle {
              padding: 20px 14px;
              label {
                cursor: pointer;
                display: block;
                position: relative;
                input {
                  float: left;
                  margin-top: 6px;
                }
                div {
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                }
                i {
                  position: absolute;
                  right: 0px;
                  top: 5px;
                }
              }
            }
            .TitleReport1Center-info {
              background: #e8e8e8;

              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
            }
          }
        }
      }
      .TitleReport1Center-text {
        margin-top: 20px;
        textarea {
          height: 350px;
          width: 100%;
          border: 0;
          font-size: 16px;
          padding: 5px 0;
          line-height: 24px;
        }
      }
    }
  }
}
.TitleReportBut {
  padding: 50px 0;
  text-align: center;
  button {
    height: 44px;
    font-size: 16px;
    border-radius: 8px;
    padding: 0 30px;
    margin: 0 10px;
    cursor: pointer;
    color: #333333;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #ffcf00;
    }
  }
}

.tmItem li::after {
  clear: both;
  display: block;
  content: "";
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.TitleReport1Center-item {
  margin-top: 10px;
  dl {
    dd {
      float: left;
      margin-right: 10px;
      height: 30px;
      background: #173d7c;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      padding: 0 20px;
      color: #ffffff;
      cursor: pointer;
      &.on {
        background-color: #ffcf00;
        color: #434343;
      }
    }
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}

.TrueTopicItem {
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #c3c3c3;
  margin-top: 10px;
  .TrueTopicItemTitle {
    height: 50px;
    border-bottom: 1px solid #c3c3c3;
    padding: 0 15px;
    .TrueTopicItemTitleFl {
      float: left;
      margin-top: 10px;
      span,
      i,
      div {
        float: left;
        margin-right: 10px;
      }
      i {
        margin-top: 5px;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      div {
        height: 30px;
        background: #636363;
        line-height: 30px;
        border-radius: 16px;
        padding: 0 10px;
        i {
          margin-top: 3px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
    .TrueTopicItemTitleFr {
      float: right;
      font-size: 12px;
      font-weight: 400;
      color: #060606;
      line-height: 50px;
    }
  }
  .TrueTopicItemFont {
    padding: 20px 30px;
    .TrueTopicItemFontTitle {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 28px;
    }
    .TrueTopicItemFontInfo {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 28px;
      margin-top: 15px;
      &:after {
        clear: both;
        display: block;
        content: "";
      }
      video {
        float: left;
        width: 280px;
      }
      .TrueTopicItemFontpl {
        padding-left: 300px;
        .TrueTopicItemFontpl-title {
          font-size: 16px;
          font-weight: 400;
          color: #636363;
        }
        .TrueTopicItemFontpl-but {
          margin-top: 10px;
          button {
            border-radius: 10px;
            border: 0;
            color: #ffffff;
            height: 44px;
            background: #173d7c;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            width: 120px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .TrueTopicItemp30 {
    padding: 15px 30px;
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    line-height: 24px;
    div {
      font-size: 12px;
      font-weight: bold;
      color: #060606;
    }
    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.purchaseShow {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 100px;
  .purchaseShowInfo {
    width: 900px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #aeaeae;
    padding: 30px;
    position: relative;
    margin: 0 auto;
    .cob {
      position: absolute;
      right: 20px;
      top: 20px;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .purchaseShow-title {
      font-size: 16px;
      font-weight: 300;
      color: #666666;
      span {
        font-weight: bold;
      }
    }
    .purchaseShow-ul {
      ul {
        margin-left: -20px;
        margin-top: 20px;
        li {
          float: left;
          padding-left: 18px;
          width: 25%;
          box-sizing: border-box;
          .p10px {
            padding: 10px;
            background: #ffffff;
            box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            border: 1px solid #d1d1d1;
            text-align: center;
            .p10px-title {
              text-align: center;
              color: #666666;
              line-height: 30px;
              span {
                color: #173d7c;
                font-size: 20px;
              }
            }
            .p10px-font {
              font-size: 16px;
              font-weight: 400;
              color: #3f414a;
              text-align: center;
              line-height: 60px;
              span {
                font-weight: bold;
                font-size: 40px;
                color: #2e2e2e;
              }
            }
            .p10px-f30 {
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              border-bottom: 1px solid #dddddd;
              line-height: 40px;
            }
            .but {
              height: 30px;
              background: #173d7c;
              border-radius: 16px;
              color: #ffffff;
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              margin: 0 auto;
              margin-top: 15px;
              display: inline-block;
              line-height: 30px;
              padding: 0 30px;
            }
            &:hover,
            &.on {
              background: #ffcf00;
              box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
              border-radius: 6px;
              border: 1px solid #d1d1d1;
              .p10px-f30 {
                border-bottom: 1px solid #7e7e7e;
              }
            }
          }
        }
      }
      &::after {
        display: block;
        clear: both;
        content: "";
      }
    }
    .purchaseShow-zf {
      margin-top: 30px;
      border: 1px solid #dddddd;
      .purchaseShow-zf-left {
        float: left;
        height: 190px;
        width: 190px;
        border-right: 1px solid #dddddd;
        background: #f2f2f2;
        div {
          margin-top: 12px;
          width: 168px;
          height: 52px;
          background: #ffcf00;
          border-radius: 0px 30px 30px 0px;
          text-align: center;
          line-height: 52px;
          font-weight: bold;
        }
      }
      .purchaseShow-zf-right {
        padding: 35px 35px 35px 225px;
        .purchaseShow-zf-right-col {
          float: left;
          width: 124px;
          height: 124px;
          background: #ffffff;
          border: 1px solid #707070;
          padding: 12px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .purchaseShow-zf-right-font {
          padding-left: 150px;
          .money {
            color: #173d7c;
            font-size: 16px;
            span {
              font-size: 48px;
              font-weight: bold;
            }
          }
          .money-bottom {
            font-size: 16px;
            line-height: 28px;
            color: #272727;
          }
        }
      }
      &::after {
        display: block;
        clear: both;
        content: "";
      }
    }
  }
}
.TitleReportItem1 {
  padding: 15px;

  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;

  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/cuowu.png") no-repeat center;
    background-size: cover;
    margin-right: 10px;
  }
  div {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    span {
      background: url("~@/assets/TrueTopic/down.png") no-repeat center;
      display: block;
      float: left;
      margin-right: 8px;
      background-size: cover;
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
    &.on {
      span {
        background: url("~@/assets/TrueTopic/up.png") no-repeat center;
        background-size: cover;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.TitleReportItem2 {
  padding: 15px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;

  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/zhengque.png") no-repeat center;
    background-size: cover;
    margin-right: 10px;
  }
  div {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    span {
      background: url("~@/assets/TrueTopic/down.png") no-repeat center;
      display: block;
      float: left;
      margin-right: 8px;
      background-size: cover;
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
    &.on {
      span {
        background: url("~@/assets/TrueTopic/up.png") no-repeat center;
        background-size: cover;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
</style>
