<template>
  <div v-if="questionData != null">
    <!-- <answer-result-tip
      style="margin-top: 20px"
      :ret="ret"
      @show-or-hide-comment="showOrHideComment"
    /> -->
    <div class="Subject-or-title" v-html="questionData.content"></div>
    <div class="Subject-or">
      <dl>
        <el-radio-group v-model="answer">
          <dd
            v-for="(choiceItem, choiceIndex) in questionData.choices"
            :key="choiceIndex"
          >
            <div class="Subject-orDdTitle SorDdTitle">
              <el-radio
                :disabled="true"
                :key="choiceItem.label"
                :label="choiceItem.label"
              >
                <span
                  class="itemSpan"
                  v-html="choiceItem.label + '.' + choiceItem.content"
                ></span>
              </el-radio>
            </div>
          </dd>
        </el-radio-group>
      </dl>
    </div>
    <div class="TrueTopicItemp30">
      <div class="fl">
        得 {{ answerData.scores }}分 共{{ questionData.score }}分
      </div>
      <div class="fr" v-if="ret != 'CORRECT'">正确答案：{{ rightAnswer }}</div>
    </div>
    <div style="padding: 15px">
      <div style="margin-top: 20px" :class="getAnswerStyle(ret)">
        <i></i>
        <span style="margin-left: 10px">{{ getAnswerTip(ret) }}</span>
        <div v-if="isRichTextNotNull(questionData.comment)" class="Subject-bor">
          <div class="Subject-pl36" style="margin-top: 20px">注解:</div>
          <div class="Subject-borMt15">
            <div class="explainCenter" v-html="questionData.comment"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import AnswerResultTip from "./AnswerResultTip.vue";
import { isRichTextNotNull } from "@/utils/validate";
export default {
  name: "SingleChoice",
  components: {}, //AnswerResultTip
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    answerData: {
      type: Object,
      default: null,
    },
    ret: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShowComment: false,
      answer: null,
      rightAnswer: "",
    };
  },
  created() {
    this.rightAnswer = this.getRightAnswer().label;
    if (this.answerData.choicesAnswers.length > 0) {
      this.answer = this.answerData.choicesAnswers[0].itemLabel;
    }
  },
  methods: {
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    showOrHideComment(bool) {
      this.isShowComment = bool;
    },
    getRightAnswer() {
      for (let i = 0; i < this.questionData.choices.length; i++) {
        if (this.questionData.choices[i].choice) {
          return this.questionData.choices[i];
        }
      }
    },
    getAnswerStyle(ret) {
      if (ret == "CORRECT") {
        return ["TitleRightItem1"];
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return ["TitleErrorItem1"];
      } /* else if (ret == "PARTIAL") {
        return ["TitleReportItem1"];
      }*/
    },
    getAnswerTip(ret) {
      if (ret == "CORRECT") {
        return "正确!";
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return "错误!";
      } /* else if (ret == "PARTIAL") {
        return "正确部分！";
      }*/
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  margin-left: 15px;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-or {
  margin-top: 10px;
  //border: 1px solid #d6d6d6;
  opacity: 1;
  //border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 10px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  padding: 30px 0;
  text-align: center;
  button {
    height: 44px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}

.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  //border-radius: 6px 6px 6px 6px;
  //border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}

.TrueTopicItemp30 {
  padding: 15px 30px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  line-height: 24px;
  div {
    font-size: 12px;
    font-weight: bold;
    color: #060606;
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>

<style lang="scss" scoped>
.TitleRightItem1 {
  background: #dfe9df;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-2.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #060606;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleErrorItem1 {
  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-3.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #060606;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #307a35;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #636363;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
</style>
