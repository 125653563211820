var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.questionData != null)?_c('div',[_c('div',{staticClass:"Subject-topicAsk",domProps:{"innerHTML":_vm._s(_vm.questionData.content)}}),_c('div',{staticClass:"Subject-bor"},[_c('div',{staticClass:"Subject-borMt15"},_vm._l((_vm.answerRow),function(row){return _c('div',{key:row,staticClass:"comprehensive br6",attrs:{"type":"flex","align-items":"stretch"}},[_c('el-row',{staticStyle:{"width":"100%"}},[_c('el-col',{staticClass:"br6_header"},[_vm._v(" "+_vm._s(_vm.answerData.matchesAnswers[row - 1].category)+" ")])],1),_vm._l((Math.ceil(
            _vm.answerData.matchesAnswers[row - 1].subItems.length / 3
          )),function(subRow){return _c('el-row',{key:subRow,staticStyle:{"width":"100%"},attrs:{"span":24}},_vm._l((subRow ==
            Math.ceil(_vm.answerData.matchesAnswers[row - 1].subItems.length / 3)
              ? _vm.answerData.matchesAnswers[row - 1].subItems.length % 3
              : 3),function(cell){return _c('el-col',{key:cell,attrs:{"span":8}},[(
                _vm.answerData.matchesAnswers[row - 1].subItems[
                  (subRow - 1) * 3 + cell - 1
                ].id != -1
              )?_c('div',{staticClass:"w25bf",staticStyle:{"padding":"4px"}},[_c('div',{staticClass:"comprehensive-item",attrs:{"title":_vm.answerData.matchesAnswers[row - 1].subItems[
                    (subRow - 1) * 3 + cell - 1
                  ].itemValue}},[_vm._v(" "+_vm._s(_vm.answerData.matchesAnswers[row - 1].subItems[ (subRow - 1) * 3 + cell - 1 ].itemValue)+" ")])]):_vm._e()])}),1)})],2)}),0)]),_c('div',{staticClass:"TrueTopicItemp30"},[_c('div',{staticClass:"fl"},[_vm._v(" 得"+_vm._s(_vm.answerData.scores)+"分 共"+_vm._s(_vm.questionData.score)+"分 ")])]),_c('div',{staticStyle:{"padding":"15px"}},[_c('div',{class:_vm.getAnswerStyle(_vm.ret),staticStyle:{"margin-top":"20px"}},[_c('i'),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.getAnswerTip(_vm.ret)))]),_c('div',{staticClass:"Subject-bor"},[(_vm.ret != 'CORRECT')?_c('div',{staticClass:"Subject-pl36"},[_vm._v("答案:")]):_vm._e(),(_vm.ret != 'CORRECT')?_c('div',{staticClass:"Subject-borMt15"},_vm._l((_vm.answerRow),function(row){return _c('div',{key:row,staticClass:"comprehensive br6",attrs:{"type":"flex","align-items":"stretch"}},[_c('el-row',{staticStyle:{"width":"100%"}},[_c('el-col',{staticClass:"br6_header"},[_vm._v(" "+_vm._s(_vm.questionMatches[row - 1].match)+" ")])],1),_vm._l((Math.ceil(
                _vm.questionMatches[row - 1].subItems.length / 3
              )),function(subRow){return _c('el-row',{key:subRow,staticStyle:{"width":"100%"},attrs:{"span":24}},_vm._l((subRow ==
                Math.ceil(_vm.questionMatches[row - 1].subItems.length / 3)
                  ? _vm.questionMatches[row - 1].subItems.length % 3
                  : 3),function(cell){return _c('el-col',{key:cell,attrs:{"span":8}},[(
                    _vm.questionMatches[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].id != -1
                  )?_c('div',{staticClass:"w25bf",staticStyle:{"padding":"4px"}},[_c('div',{staticClass:"comprehensive-item",attrs:{"title":_vm.questionMatches[row - 1].subItems[
                        (subRow - 1) * 3 + cell - 1
                      ].itemValue}},[_vm._v(" "+_vm._s(_vm.questionMatches[row - 1].subItems[ (subRow - 1) * 3 + cell - 1 ].itemValue)+" ")])]):_vm._e()])}),1)})],2)}),0):_vm._e(),(_vm.isRichTextNotNull(_vm.questionData.comment))?_c('div',{staticClass:"Subject-pl36",staticStyle:{"margin-top":"20px"}},[_vm._v(" 注解: ")]):_vm._e(),(_vm.isRichTextNotNull(_vm.questionData.comment))?_c('div',{staticClass:"Subject-borMt15"},[_c('div',{staticClass:"explainCenter",domProps:{"innerHTML":_vm._s(_vm.questionData.comment)}})]):_vm._e()])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }