<template>
  <div v-if="topicData != null">
    <div class="Subject-topicAsk" v-html="topicData.topicVo.content"></div>
    <div
      v-for="(questionItem, questionIndex) in topicData.topicVo.questions"
      :key="questionIndex"
    >
      <!--多选题-->
      <div v-if="questionItem.type == 'MULTIPLE_CHOICE'">
        <multiple-choice
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
      <!--判断题-->
      <div v-if="questionItem.type == 'TRUE_OR_FALSE'">
        <true-or-false
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
      <!--单选题-->
      <div v-if="questionItem.type == 'SINGLE_CHOICE'">
        <single-choice
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
      <!--问答题-->
      <div v-if="questionItem.type == 'ESSAY_QUESTION'">
        <essay-question
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
      <!--填空题-->
      <div v-if="questionItem.type == 'GAP_FILLING'">
        <gap-filling
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
      <!--匹配题-->
      <div v-if="questionItem.type == 'MATCHING_ITEM'">
        <matching-item
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
      <!--会计分录题-->
      <div v-if="questionItem.type == 'ACCOUNTING'">
        <accounting
          :ret="topicData.answers[questionIndex].ret"
          :questionData="questionItem"
          :answerData="topicData.answers[questionIndex]"
        />
      </div>
    </div>
    <!-- <div
      v
      class="Subject-bor"
      v-for="(itemField, fieldIndex) in topicData.questions[0].fields"
      :key="fieldIndex"
    >
      <div class="Subject-pl36">参考答案</div>
      <div class="Subject-borMt15" v-html="itemField.option"></div>
    </div> -->
  </div>
</template>
<script>
import SingleChoice from "@/components/TopicResult/SingleChoice.vue";
import TrueOrFalse from "@/components/TopicResult/TrueOrFalse.vue";
import MultipleChoice from "@/components/TopicResult/MultipleChoice.vue";
import EssayQuestion from "@/components/TopicResult/EssayQuestion.vue";
import GapFilling from "@/components/TopicResult/GapFilling.vue";
import MatchingItem from "@/components/TopicResult/MatchingItem.vue";
import Accounting from "@/components/TopicResult/Accounting.vue";
export default {
  name: "Comprehensive",
  components: {
    MultipleChoice,
    SingleChoice,
    TrueOrFalse,
    EssayQuestion,
    GapFilling,
    Accounting,
    MatchingItem,
  },
  props: {
    topicData: {
      type: Object,
      default: null,
    },
    presentation: {
      type: String,
      default: null,
    },
    isShowSubjecTitle: {
      type: Boolean,
      default: true,
    },
    isAnswerSubmit: {
      type: Boolean,
      default: false,
    },
    isShowFavorite: {
      //是否显示收藏操作
      type: Boolean,
      default: true,
    },
    refKind: {
      type: String,
      default: null,
    },
    refId: {
      type: Number,
      default: -1,
    },
    topicIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  created() {
    if (this.topicData == null) {
      return;
    }
  },
  methods: {
    setAnswers(index, answer) {
      this.$emit("set-answers", index, answer);
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-topicAsk {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  padding: 30px 0;
  text-align: center;
  button {
    height: 44px;

    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}

.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>
