import request from "@/utils/request";

const BASE_PATH = "/front/ground/topic-result/";
export function coldcallTopicResultSubmit(data) {
  return request({
    url: BASE_PATH + "coldcall",
    method: "post",
    data,
  });
}

export function examTopicResultSubmit(data) {
  return request({
    url: BASE_PATH + "exam",
    method: "post",
    data,
  });
}

export function deleteExamTopicResult(resultId) {
  return request({
    url: BASE_PATH + "exam/" + resultId,
    method: "delete",
  });
}

export function practiceTopicResultSubmit(data) {
  return request({
    url: BASE_PATH + "practice",
    method: "post",
    data,
  });
}

export function quizTopicResultSubmit(data) {
  return request({
    url: BASE_PATH + "quizzes",
    method: "post",
    data,
  });
}
export function getTopicResultByRefKingAndId(refKing, id) {
  return request({
    url: BASE_PATH + refKing + "/" + id,
    method: "get",
  });
}
export function getTopicResultColdCallMemberListByTopic(query) {
  return request({
    url: BASE_PATH + "coldcall/memberlist", // + topicId,
    method: "get",
    params: query,
  });
}
export function getColdCallMemberResult(memberId, topicId) {
  return request({
    url: BASE_PATH + "coldcall/" + memberId + "/" + topicId,
    method: "get",
  });
}

export function getMemberExam(query){
  return request({
    url: "front/member/exam",
    method: "get",
    params: query,
  });
}