<template>
  <div
    v-if="
      topicData != null &&
      topicData.explain != null &&
      (topicData.explain.mediaType != '' ||
        isRichTextNotNull(topicData.explain.content))
    "
  >
    <div class="Subject-bor">
      <div v-if="topicData.explain.title != ''" class="Subject-pl36">
        {{ topicData.explain.title }}
      </div>
      <div class="Subject-borMt15" v-html="topicData.explain.content"></div>
      <!-- topicData.explain.valid  -->
      <div
        class="Subject-borMt15"
        style="color: red"
        v-if="!isValid && topicData.explain.price != 0"
      >
        购买后可以查看<span v-if="topicData.explain.mediaType == 'VIDEO'"
          >视频</span
        ><span v-else-if="topicData.explain.mediaType == 'IMAGE'">图文</span
        ><span v-else-if="topicData.explain.mediaType == 'PDF'">PDF</span>解析
      </div>
      <div
        v-if="!isValid && topicData.explain.price != 0"
        class="ExaminationCenterUl-but"
      >
        <div class="but1" @click="pay()">立即购买</div>
      </div>
      <div
        id="videoPlayer2Div"
        ref="videoPlayer2Div"
        style="top: 3.571vw; z-index: 999;"
      >
        <video-player
          v-if="
            topicData.explain.mediaType == 'VIDEO' &&
            (topicData.explain.price == 0 || isValid)
          "
          style="margin-top: 20px"
          id="videoPlayer2"
          ref="videoPlayer2"
          class="vjs-custom-skin"
          :options="playerOptions2"
          @setDrag="setDrag"
          v-drag
          drag="false"
        />
      </div>
      <img
        v-if="
          topicData.explain.mediaType == 'IMAGE' &&
          (topicData.explain.price == 0 || isValid)
        "
        width="100%"
        style="margin-top: 20px"
        :src="topicData.explain.mediaFile"
      />
      <!--http://frontpage.siriux.cygnux.cn/upload/pdf/6b/cf15/d572/f9aa/abd12dee313558f19b.pdf 正式服务器要是不显示就加载前缀-->
      <iframe
        v-if="
          topicData.explain.mediaType == 'PDF' &&
          (topicData.explain.price == 0 || isValid)
        "
        style="margin-top: 20px"
        width="100%"
        height="700px"
        :src="topicData.explain.mediaFile"
      />
    </div>

    <div v-if="isPay" class="NewPayShow">
      <div class="NewTopicInfo">
        <div class="close" @click="closePay()"></div>
        <div class="infoTitle">
          <div class="title">选择适合您的VIP计划</div>
          <div class="title-font">购买多多，优惠多多</div>
          <div class="title-font">
            注意:如已扫描支付，请勿重复扫描支付，若长时间未返回订购成功，可去购买记录查看结果!
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          <img src="~@/assets/outline/weipaylogo.png" />
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          "
        >
          <div class="warBr">
            <img v-if="scanningCodeUrl" :src="scanningCodeUrl" />
            <span v-else>正在创建订单...</span>
            <!-- <img  src="~@/assets/code.png" /> -->
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          应付金额:<span style="color: #173d7c; font-weight: bold"
            >￥{{ topicData.explain.price / 100 }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import "video.js/dist/video-js.css";
// import "@/components/VueVideoPlayer/custom-theme.css";
import videoPlayer from "@/components/VueVideoPlayer/player.vue";
import { isRichTextNotNull } from "@/utils/validate";
import {
  createOrder,
  submitOrder,
  getOrderDetail,
} from "@/api/orderController";
export default {
  name: "TopicExplain",
  components: {
    videoPlayer,
  },
  props: {
    topicData: {
      type: Object,
      default: null,
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    topicData: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.play();
      },
      deep: true,
    },
    valid: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.isValid = this.valid;
      },
    },
  },
  data() {
    return {
      playerOptions2: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideoDocBtn: false,
        isShowDragTip: false,
        isDrag: true,
        showDragTipDuration: 3000,
      },
      isPay: false,
      scanningCodeUrl: "",
      orderStatusTimer: -1,
      isValid: false,
      videoPlayer2Width: 0,
      videoPlayer2Height: 0,
      videoPlayer2Top: 0,
      videoPlayer2Left: 0,
    };
  },
  created() {
    this.play();
    this.isValid = this.valid;
    if (
      this.topicData != null &&
      this.topicData.explain != null &&
      this.topicData.explain.mediaType == "VIDEO"
    ) {
      //this.initVideo();
    }
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          console.log("onmousedown:" + oDiv.getAttribute("drag"));
          if (oDiv.getAttribute("drag") === "false") {
            return;
          }
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };

          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  methods: {
    initVideo() {
      var that = this;
      setTimeout(function () {
        let width = that.$refs.videoPlayer2Div.clientWidth;
        let height = that.$refs.videoPlayer2Div.clientHeight;
        let top = that.$refs.videoPlayer2Div.offsetTop;
        let left = that.$refs.videoPlayer2Div.offsetLeft;
        document.getElementById("videoPlayer2").style.position = "absolute";
        document.getElementById("videoPlayer2").style.left = left + "px";
        document.getElementById("videoPlayer2").style.top = top + "px";
        document.getElementById("videoPlayer2").style.width = width + "px";
        document.getElementById("videoPlayer2").style.height = height + "px";
        that.videoPlayer2Width = width;
        that.videoPlayer2Height = height;
        that.videoPlayer2Top = top;
        that.videoPlayer2Left = left;
      }, 100);
    },
    setDrag() {
      var drag = this.$refs.videoPlayer2.$el.getAttribute("drag");
      if (drag === "false") {
        this.$refs.videoPlayer2Div.style.position = "sticky";
        this.$refs.videoPlayer2.$el.setAttribute("drag", "true");
      } else {
        this.$refs.videoPlayer2.$el.setAttribute("drag", "false");
        this.$refs.videoPlayer2Div.style.position = "static";
        this.$refs.videoPlayer2.$el.style.top = this.videoPlayer2Top + "px";
        this.$refs.videoPlayer2.$el.style.left = this.videoPlayer2Left + "px";
      }
    },
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    play() {
      if (
        this.topicData != null &&
        this.topicData.explain.mediaType == "VIDEO"
      ) {
        this.playerOptions2.sources = [
          { type: "video/mp4", src: this.topicData.explain.mediaFile },
        ];
      }
    },
    closePay() {
      this.isPay = false;
      window.clearTimeout(window.orderStatusTimer);
    },
    pay() {
      this.isPay = true;
      let product = { items: [{}] };
      product.subject = this.topicData.explain.title;
      product.items[0].kind = "EXPLAIN";
      product.items[0].refId = this.topicData.explain.topicId;
      product.items[0].name = this.topicData.explain.title;
      product.items[0].count = 1;
      this.scanningCodeUrl = "";
      createOrder(product).then((res) => {
        if (res.code == 20000) {
          this.submitOrder(res.data);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    submitOrder(data) {
      submitOrder(data).then((res) => {
        if (res.code == 20000) {
          this.scanningCodeUrl = res.data.qrImage;
          this.getOrderDetail(res.data.order.serialNum);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getOrderDetail(serialNum) {
      window.clearTimeout(window.orderStatusTimer);
      getOrderDetail(serialNum).then((res) => {
        if (res.code == 20000) {
          let status = res.data.status;
          // -3 退款 -2 失败， -1 取消， 0 未处理 1 支付中 2 已支付
          if (status == -3) {
            this.$baseMessage(
              "此订单已退款!",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == -2) {
            this.$baseMessage(
              "订单已失败!请稍后再试",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == 1) {
            let that = this;
            window.orderStatusTimer = setTimeout(function () {
              that.getOrderDetail(serialNum);
            }, 1000);
          } else if (status == 0) {
            this.$baseMessage(
              "订单未处理!请稍后再试",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == 2) {
            this.$baseMessage(
              "恭喜您已订购成功！",
              "success",
              "vab-hey-message-success"
            );
            this.closePay();
            //this.getExamList();
            //this.topicData.explain.valid = true;
            this.isValid = true;
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-bor {
  background: #ffffff;
  //border-radius: 6px 6px 6px 6px;
  //border: 1px solid #aeaeae;
  //margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
.ExaminationCenterUl-but {
  text-align: center;
  padding-top: 20px;
  .but1 {
    width: 120px;
    height: 50px;
    background: #173d7c;
    border-radius: 10px;
    display: inline-block;
    line-height: 50px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    margin: 0 5px;
  }
}
.NewPayShow {
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 30px 20px 30px;
  z-index: 1;
  .NewTopicInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 830px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    .infoTitle {
      margin: 0 35px;
      padding: 15px 20px;
      background: #ffcf00;
      color: #000;
      border-radius: 6px;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        line-height: 50px;
      }
      .title-font {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        padding-bottom: 10px;
      }
    }
    .warBr {
      border: 1px solid #cccccc;
      padding: 0px;
      margin-top: 20px;
      text-align: center;
      width: 40%;
      img {
        width: 100%;
      }
    }
    .warBr-title {
      text-align: center;
      line-height: 36px;
    }
    .NewTopic-ul {
      padding-top: 30px;
      ul {
        li {
          padding: 12px 30px;
          &::after {
            clear: both;
            display: block;
            content: "";
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
    .close {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: url("~@/assets/icon-gb.png") no-repeat center;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
