<template>
  <div v-if="questionData != null">
    <!-- <answer-result-tip
      style="margin-top: 20px"
      :ret="ret"
      @show-or-hide-comment="showOrHideComment"
    /> -->
    <div class="Subject-topicAsk" v-html="questionData.content"></div>
    <!-- <el-row class="comprehensive">
      <draggable v-model="matcheOptions" group="people" itemKey="itemId">
        <transition-group class="h300BK_top">
          <div
            v-for="matcheOptionItem in matcheOptions"
            :key="matcheOptionItem.itemId"
            class="w25bf"
          >
            <div class="comprehensive-item">
              {{ matcheOptionItem.itemValue }}
            </div>
          </div>
        </transition-group>
      </draggable>
    </el-row>
    <el-row
      v-for="row in answerRow"
      :key="row"
      class="comprehensive br6"
      type="flex"
      align-items="stretch"
    >
      <el-col
        v-for="cell in row * 3 > questionMatches.length
          ? questionMatches.length - (row - 1) * 3
          : 3"
        :key="cell"
        :span="8"
        :style="colStyle[(cell - 1) % 3]"
      >
        <div class="br6_header">
          {{ questionMatches[(row - 1) * 3 + cell - 1].match }}
        </div>
        <div
          v-for="(subItem, subItemIndex) in questionMatches[
            (row - 1) * 3 + cell - 1
          ].subItems"
          :key="subItemIndex"
        >
          <draggable
            style="padding: 4px"
            v-model="subItem.answer"
            group="people"
            itemKey="itemId"
          >
            <transition-group class="h300BK">
              <div
                class="w25bf"
                v-for="answerItem in subItem.answer"
                :key="answerItem.itemId"
              >
                <div class="comprehensive-item">
                  {{ answerItem.itemValue }}
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </el-col>
    </el-row> -->
    <div class="Subject-bor">
      <div class="Subject-borMt15">
        <div
          v-for="row in answerRow"
          :key="row"
          class="comprehensive br6"
          type="flex"
          align-items="stretch"
        >
          <!-- <el-col
            v-for="cell in row * 3 > answerData.matchesAnswers.length
              ? answerData.matchesAnswers.length - (row - 1) * 3
              : 3"
            :key="cell"
            :span="8"
            :style="colStyle[(cell - 1) % 3]"
          >
            <div class="br6_header">
              {{ answerData.matchesAnswers[(row - 1) * 3 + cell - 1].category }}
            </div>
            <div
              v-for="(subItem, subItemIndex) in answerData.matchesAnswers[
                (row - 1) * 3 + cell - 1
              ].subItems"
              :key="subItemIndex"
            >
              <div style="padding: 4px" class="w25bf">
                <div class="comprehensive-item">
                  {{ subItem.itemValue }}
                </div>
              </div>
            </div>
          </el-col> -->
          <el-row style="width: 100%">
            <el-col class="br6_header">
              {{ answerData.matchesAnswers[row - 1].category }}
            </el-col>
          </el-row>
          <el-row
            style="width: 100%"
            v-for="subRow in Math.ceil(
              answerData.matchesAnswers[row - 1].subItems.length / 3
            )"
            :key="subRow"
            :span="24"
          >
            <el-col
              :span="8"
              v-for="cell in subRow ==
              Math.ceil(answerData.matchesAnswers[row - 1].subItems.length / 3)
                ? answerData.matchesAnswers[row - 1].subItems.length % 3
                : 3"
              :key="cell"
            >
              <div
                v-if="
                  answerData.matchesAnswers[row - 1].subItems[
                    (subRow - 1) * 3 + cell - 1
                  ].id != -1
                "
                style="padding: 4px"
                class="w25bf"
              >
                <div
                  class="comprehensive-item"
                  :title="
                    answerData.matchesAnswers[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].itemValue
                  "
                >
                  {{
                    answerData.matchesAnswers[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].itemValue
                  }}
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="TrueTopicItemp30">
      <div class="fl">
        得{{ answerData.scores }}分 共{{ questionData.score }}分
      </div>
      <!-- <div class="fr">
        正确答案：<span
          v-for="(item, itemIndex) in rightAnswer"
          :key="itemIndex"
          >{{ item }}、</span
        >
      </div> -->
    </div>
    <div style="padding: 15px">
      <div style="margin-top: 20px" :class="getAnswerStyle(ret)">
        <i></i>
        <span style="margin-left: 10px">{{ getAnswerTip(ret) }}</span>
        <div class="Subject-bor">
          <div v-if="ret != 'CORRECT'" class="Subject-pl36">答案:</div>
          <div v-if="ret != 'CORRECT'" class="Subject-borMt15">
            <div
              v-for="row in answerRow"
              :key="row"
              class="comprehensive br6"
              type="flex"
              align-items="stretch"
            >
              <!-- <el-col
                v-for="cell in row * 3 > questionMatches.length
                  ? questionMatches.length - (row - 1) * 3
                  : 3"
                :key="cell"
                :span="8"
                :style="colStyle[(cell - 1) % 3]"
              >
                <div class="br6_header">
                  {{ questionMatches[(row - 1) * 3 + cell - 1].match }}
                </div>
                <div
                  v-for="(subItem, subItemIndex) in questionMatches[
                    (row - 1) * 3 + cell - 1
                  ].subItems"
                  :key="subItemIndex"
                >
                  <div style="padding: 4px" class="w25bf">
                    <div class="comprehensive-item">
                      {{ subItem.itemValue }}
                    </div>
                  </div>
                </div>
              </el-col> -->
              <el-row style="width: 100%">
                <el-col class="br6_header">
                  {{ questionMatches[row - 1].match }}
                </el-col>
              </el-row>
              <el-row
                style="width: 100%"
                v-for="subRow in Math.ceil(
                  questionMatches[row - 1].subItems.length / 3
                )"
                :key="subRow"
                :span="24"
              >
                <el-col
                  :span="8"
                  v-for="cell in subRow ==
                  Math.ceil(questionMatches[row - 1].subItems.length / 3)
                    ? questionMatches[row - 1].subItems.length % 3
                    : 3"
                  :key="cell"
                >
                  <div
                    v-if="
                      questionMatches[row - 1].subItems[
                        (subRow - 1) * 3 + cell - 1
                      ].id != -1
                    "
                    style="padding: 4px"
                    class="w25bf"
                  >
                    <div
                      class="comprehensive-item"
                      :title="
                        questionMatches[row - 1].subItems[
                          (subRow - 1) * 3 + cell - 1
                        ].itemValue
                      "
                    >
                      {{
                        questionMatches[row - 1].subItems[
                          (subRow - 1) * 3 + cell - 1
                        ].itemValue
                      }}
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div
            v-if="isRichTextNotNull(questionData.comment)"
            class="Subject-pl36"
            style="margin-top: 20px"
          >
            注解:
          </div>
          <div
            v-if="isRichTextNotNull(questionData.comment)"
            class="Subject-borMt15"
          >
            <div class="explainCenter" v-html="questionData.comment"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import AnswerResultTip from "./AnswerResultTip.vue";
//import draggable from "vuedraggable";
import { isRichTextNotNull } from "@/utils/validate";
export default {
  name: "MatchingItem",
  //components: { draggable, AnswerResultTip },
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    answerData: {
      type: Object,
      default: null,
    },
    ret: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShowComment: false,
      questionMatches: [],
      matcheOptions: [],
      answerRow: 1,
      colStyle: [
        { "border-right": "1px solid #aeaeae" },
        {
          "border-right": "1px solid #aeaeae",
          "border-left": "1px solid #aeaeae;",
        },
        {},
      ],
    };
  },
  created() {
    let matches = this.questionData.matches;
    let matchesLength = matches.length;
    for (let i = 0; i < matchesLength; i++) {
      let isAt = false;
      let questionMatchesLength = this.questionMatches.length;
      for (let j = 0; j < questionMatchesLength; j++) {
        if (
          matches[i].match == this.questionMatches[j].match &&
          matches[i].match != ""
        ) {
          this.questionMatches[j].subItems.push({
            itemId: matches[i].id,
            itemValue: matches[i].content,
            //answer: [],
          });
          this.$set(
            this.questionMatches[j].subItems[
              this.questionMatches[j].subItems.length - 1
            ],
            "answer",
            []
          );
          isAt = true;
          break;
        }
      }
      if (!isAt && matches[i].match != "") {
        this.questionMatches.push({
          match: matches[i].match,
        });
        this.$set(
          this.questionMatches[this.questionMatches.length - 1],
          "subItems",
          []
        );
        this.questionMatches[this.questionMatches.length - 1].subItems.push({
          itemId: matches[i].id,
          itemValue: matches[i].content,
          //answer: [],
        });
        this.$set(
          this.questionMatches[this.questionMatches.length - 1].subItems[
            this.questionMatches[this.questionMatches.length - 1].subItems
              .length - 1
          ],
          "answer",
          []
        );
        // this.questionMatches.push({
        //   match: matches[i].match,
        //   subItems: [
        //     {
        //       itemId: matches[i].id,
        //       itemValue: matches[i].content,
        //       answer: [],
        //     },
        //   ],
        // });
      }
      this.matcheOptions.push({
        itemId: matches[i].id,
        itemValue: matches[i].content,
      });
    }
    //this.answerRow = Math.ceil(this.questionMatches.length / 3);
    this.answerRow = this.questionMatches.length;
    // console.log(
    //   "questionMatches.length:" +
    //     this.questionMatches.length +
    //     ",matcheOptions:" +
    //     this.matcheOptions.length
    // );
    // console.log(
    //   "questionMatches.length:" +
    //     JSON.stringify(this.questionMatches) +
    //     ",matcheOptions:" +
    //     JSON.stringify(this.matcheOptions)
    // );
  },
  methods: {
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    showOrHideComment(bool) {
      this.isShowComment = bool;
    },
    getAnswerStyle(ret) {
      if (ret == "CORRECT") {
        return ["TitleRightItem1"];
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return ["TitleErrorItem1"];
      } /* else if (ret == "PARTIAL") {
        return ["TitleReportItem1"];
      }*/
    },
    getAnswerTip(ret) {
      if (ret == "CORRECT") {
        return "正确!";
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return "错误!";
      } /*else if (ret == "PARTIAL") {
        return "正确部分！";
      }*/
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  margin-left: 15px;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-topicAsk {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.mt15 {
  margin-top: 15px;
}
.lhpbtitle {
  font-weight: bold;
  line-height: 32px;
}
.itemInput input {
  height: 45px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #989898;
}
.comprehensive {
  border: 1px solid #aeaeae;
  padding: 4px;
  margin-top: 15px;
  .comprehensive-item {
    height: 45px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    border: 1px solid #707070;
    line-height: 45px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
  }
}
.Subject-or {
  margin-top: 10px;
  //border: 1px solid #d6d6d6;
  opacity: 1;
  //border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 10px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  padding: 30px 0;
  text-align: center;
  button {
    height: 44px;

    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}
.br6 {
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  padding: 0;
  background: #ffffff;
  .br6_header {
    height: 50px;
    background: #e8e8e8;
    border-bottom: 1px solid #aeaeae;
    text-align: center;
    line-height: 50px;
  }
}
.h300BK_top {
  display: block;
  //min-height: 214px;
  height: 49px;
  .w25bf {
    width: 25%;
    display: block;
    float: left;
    padding: 4px;
  }
  .sortable-chosen {
    width: 25%;
    left: auto;
    top: auto;
  }
}
.h300BK {
  display: block;
  //min-height: 210px;
  height: 49px;
  position: relative;
  margin: 4px;
  // &:hover {
  //   background-color: #dddddd;
  //   border-radius: 6px;
  // }
  border: 1px dashed rgb(112, 112, 112);
  border-radius: 6px;
  .w25bf {
    margin: -1px;
    padding: 0px;
  }
}
.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  //border-radius: 6px 6px 6px 6px;
  //border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}

.TrueTopicItemp30 {
  padding: 15px 30px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  line-height: 24px;
  div {
    font-size: 12px;
    font-weight: bold;
    color: #060606;
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>
<style lang="scss" scoped>
.TitleRightItem1 {
  background: #dfe9df;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-2.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #060606;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleErrorItem1 {
  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-3.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #060606;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #307a35;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #636363;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
</style>
