<template>
  <div v-if="questionData != null">
    <!-- <answer-result-tip
      style="margin-top: 20px"
      :ret="ret"
      @show-or-hide-comment="showOrHideComment"
    /> -->
    <div class="Subject-topicAsk" v-html="questionData.content"></div>
    <el-row class="mt15">
      <el-col :span="11" v-for="(item, i) in questionData.fields" :key="i">
        <div class="fllh36" :style="{ 'margin-top': i > 1 ? '15px' : '0px' }">
          {{ item.label }}
        </div>
        <div
          class="optionInfo"
          :style="{ 'margin-top': i > 1 ? '15px' : '0px' }"
        >
          <input
            v-model="answer[i]"
            :disabled="true"
            type="text"
            :placeholder="'请输入第' + (i + 1) + '空答案'"
          />
        </div>
      </el-col>
    </el-row>
    <div style="padding: 15px">
      <div style="margin-top: 20px" :class="getAnswerStyle(ret)">
        <i></i>
        <span style="margin-left: 10px">{{ getAnswerTip(ret) }}</span>
        <div class="Subject-bor">
          <div v-if="ret != 'CORRECT'" class="Subject-pl36">答案:</div>
          <div v-if="ret != 'CORRECT'">
            <div
              v-for="(itemField, fieldIndex) in questionData.fields"
              :key="fieldIndex"
              class="Subject-borMt15"
            >
              第{{ fieldIndex + 1 }}空答案:{{ itemField.option }}
            </div>
          </div>
          <div
            v-if="isRichTextNotNull(questionData.comment)"
            class="Subject-pl36"
            style="margin-top: 20px"
          >
            注解:
          </div>
          <div
            v-if="isRichTextNotNull(questionData.comment)"
            class="Subject-borMt15"
          >
            <div class="explainCenter" v-html="questionData.comment"></div>
          </div>
          <!-- <div class="Subject-borMt15" v-html="topicData.explain.content"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import AnswerResultTip from "./AnswerResultTip.vue";
import { isRichTextNotNull } from "@/utils/validate";
export default {
  name: "GapFilling",
  //components: { AnswerResultTip },
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    answerData: {
      type: Object,
      default: null,
    },
    ret: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      answer: [],
      isShowComment: false,
      rightAnswer: [],
    };
  },
  created() {
    let fields = this.questionData.fields;
    let fieldsLength = fields.length;
    for (let i = 0; i < fieldsLength; i++) {
      this.answer.push(this.answerData.fieldsAnswers[i].itemValue);
      this.rightAnswer.push(fields[i].option);
    }
  },
  methods: {
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    showOrHideComment(bool) {
      this.isShowComment = bool;
    },
    getAnswerStyle(ret) {
      if (ret == "CORRECT") {
        return ["TitleRightItem1"];
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return ["TitleErrorItem1"];
      } /* else if (ret == "PARTIAL") {
        return ["TitleReportItem1"];
      }*/
    },
    getAnswerTip(ret) {
      if (ret == "CORRECT") {
        return "正确!";
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return "错误!";
      } /* else if (ret == "PARTIAL") {
        return "正确部分！";
      }*/
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-topicAsk {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  padding: 30px 0;
  text-align: center;
  button {
    height: 44px;

    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}

.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}
.mt15 {
  margin-top: 15px;
}
.fllh36 {
  float: left;
  line-height: 45px;
  width: 30px;
  text-align: center;
}
.optionInfo {
  padding-left: 30px;
  input {
    height: 45px;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #989898;
  }
}
.Subject-bor {
  background: #ffffff;
  //border-radius: 6px 6px 6px 6px;
  //border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}

.TrueTopicItemp30 {
  padding: 15px 30px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  line-height: 24px;
  div {
    font-size: 12px;
    font-weight: bold;
    color: #060606;
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>
<style lang="scss" scoped>
.TitleRightItem1 {
  background: #dfe9df;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-2.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #060606;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleErrorItem1 {
  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-3.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #060606;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #307a35;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  // div {
  //   float: right;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #636363;
  //   cursor: pointer;
  //   span {
  //     background: url("~@/assets/TrueTopic/down.png") no-repeat center;
  //     display: block;
  //     float: left;
  //     margin-right: 8px;
  //     background-size: cover;
  //     width: 16px;
  //     height: 16px;
  //     margin-top: 3px;
  //   }
  //   &.on {
  //     span {
  //       background: url("~@/assets/TrueTopic/up.png") no-repeat center;
  //       background-size: cover;
  //     }
  //   }
  // }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
</style>
