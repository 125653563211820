var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.topicData != null &&
    _vm.topicData.explain != null &&
    (_vm.topicData.explain.mediaType != '' ||
      _vm.isRichTextNotNull(_vm.topicData.explain.content))
  )?_c('div',[_c('div',{staticClass:"Subject-bor"},[(_vm.topicData.explain.title != '')?_c('div',{staticClass:"Subject-pl36"},[_vm._v(" "+_vm._s(_vm.topicData.explain.title)+" ")]):_vm._e(),_c('div',{staticClass:"Subject-borMt15",domProps:{"innerHTML":_vm._s(_vm.topicData.explain.content)}}),(!_vm.isValid && _vm.topicData.explain.price != 0)?_c('div',{staticClass:"Subject-borMt15",staticStyle:{"color":"red"}},[_vm._v(" 购买后可以查看"),(_vm.topicData.explain.mediaType == 'VIDEO')?_c('span',[_vm._v("视频")]):(_vm.topicData.explain.mediaType == 'IMAGE')?_c('span',[_vm._v("图文")]):(_vm.topicData.explain.mediaType == 'PDF')?_c('span',[_vm._v("PDF")]):_vm._e(),_vm._v("解析 ")]):_vm._e(),(!_vm.isValid && _vm.topicData.explain.price != 0)?_c('div',{staticClass:"ExaminationCenterUl-but"},[_c('div',{staticClass:"but1",on:{"click":function($event){return _vm.pay()}}},[_vm._v("立即购买")])]):_vm._e(),_c('div',{ref:"videoPlayer2Div",staticStyle:{"top":"3.571vw","z-index":"999"},attrs:{"id":"videoPlayer2Div"}},[(
          _vm.topicData.explain.mediaType == 'VIDEO' &&
          (_vm.topicData.explain.price == 0 || _vm.isValid)
        )?_c('video-player',{directives:[{name:"drag",rawName:"v-drag"}],ref:"videoPlayer2",staticClass:"vjs-custom-skin",staticStyle:{"margin-top":"20px"},attrs:{"id":"videoPlayer2","options":_vm.playerOptions2,"drag":"false"},on:{"setDrag":_vm.setDrag}}):_vm._e()],1),(
        _vm.topicData.explain.mediaType == 'IMAGE' &&
        (_vm.topicData.explain.price == 0 || _vm.isValid)
      )?_c('img',{staticStyle:{"margin-top":"20px"},attrs:{"width":"100%","src":_vm.topicData.explain.mediaFile}}):_vm._e(),(
        _vm.topicData.explain.mediaType == 'PDF' &&
        (_vm.topicData.explain.price == 0 || _vm.isValid)
      )?_c('iframe',{staticStyle:{"margin-top":"20px"},attrs:{"width":"100%","height":"700px","src":_vm.topicData.explain.mediaFile}}):_vm._e()]),(_vm.isPay)?_c('div',{staticClass:"NewPayShow"},[_c('div',{staticClass:"NewTopicInfo"},[_c('div',{staticClass:"close",on:{"click":function($event){return _vm.closePay()}}}),_vm._m(0),_vm._m(1),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%"}},[_c('div',{staticClass:"warBr"},[(_vm.scanningCodeUrl)?_c('img',{attrs:{"src":_vm.scanningCodeUrl}}):_c('span',[_vm._v("正在创建订单...")])])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%","margin-top":"20px"}},[_vm._v(" 应付金额:"),_c('span',{staticStyle:{"color":"#173d7c","font-weight":"bold"}},[_vm._v("￥"+_vm._s(_vm.topicData.explain.price / 100))])])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoTitle"},[_c('div',{staticClass:"title"},[_vm._v("选择适合您的VIP计划")]),_c('div',{staticClass:"title-font"},[_vm._v("购买多多，优惠多多")]),_c('div',{staticClass:"title-font"},[_vm._v(" 注意:如已扫描支付，请勿重复扫描支付，若长时间未返回订购成功，可去购买记录查看结果! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%","margin-top":"20px"}},[_c('img',{attrs:{"src":require("@/assets/outline/weipaylogo.png")}})])
}]

export { render, staticRenderFns }